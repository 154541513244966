/* RESET */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: black;
  background: white;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
q,
blockquote {
  quotes: "" "";
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
a img {
  border: none;
}

/*
*  COLORS
*  Links (light blue, a bit darker): #536980
*  h2 (light blue): #869BB1
*  H2 (dark blue): #002F5D
*  Lines, Borders (very light blue): #BFCBD6
*/

/* GENERAL */

body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

a,
a:visited {
  color: #536980;
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1;
}

h2 {
  color: #002f5d;
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 36px 0 24px 0;
}
@media screen and (min-width: 671px) {
  h2 {
    margin: 36px 0 24px -24px;
  }
}

h3 {
  color: #002f5d;
  font-size: 1rem;
  margin: 2px 0 22px 0;
}

h4 {
  color: #002f5d;
  font-size: 1rem;
  font-weight: normal;
}

ul,
ol {
  margin-bottom: 24px;
}

blockquote,
.blockquote {
  margin-bottom: 36px;
}

blockquote .source,
.blockquote .source {
  text-align: right;
  font-size: 14px;
  font-style: italic;
  margin: -24px 0 0 0;
}

.section {
  clear: both;
  margin-bottom: 36px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

/* STRUCTURE */

#wrapper {
  display: flex;
}

#container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* HEADER */
#header {
  position: relative;
  flex-shrink: 0;
}

#logo {
  background: url(../images/logo.svg) 0% 0% no-repeat;
  height: 72px;
  width: 244px;
  margin: 24px 0;
  text-indent: -9999em;
  position: relative;
  left: -8px;
}

#claim {
  position: absolute;
  right: 0;
  top: 0;
  height: 84px;
  margin-bottom: 12px;
  width: 100%;
  background: url(../images/claim.png) 100% 100% no-repeat;
  text-indent: -9999em;
}

#navigation {
  border-top: #eee 1px solid;
  margin: 0;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (min-width: 671px) {
  #navigation {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

#navigation li {
  list-style: none;
  position: relative;
  display: block;
  margin-right: 1em;
}

@media screen and (min-width: 671px) {
  #navigation li {
    margin-right: 0;
  }
}

#navigation a {
  display: block;
  line-height: 32px;
  font-size: 14px;
}

#navigation a:hover {
  text-decoration: none;
}

#navigation a.active {
  color: #002f5d;
  font-weight: 500;
  cursor: default;
}

/* CONTENT */

#content {
  margin: 0;
  flex-grow: 1;
  padding-bottom: 24px;
  padding-top: 24px;
}
@media screen and (min-width: 671px) {
  #content {
    margin: 0 72px;
  }
}

#content.team {
  margin: 0;
}

#content.contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 78px;
}

#content p {
  margin-bottom: 24px;
}

/* FOOTER */

#footer {
  border-top: #eee 1px solid;
  min-height: 50px;
  position: relative;
}

#footer_images {
  position: absolute;
  right: 0;
}

/* STICKY FOOTER */

html,
body {
  height: 100%;
}
#container {
  min-height: 100vh;
  height: 100%;
  margin: 0 auto -72px; /* the bottom margin is the negative value of the footer's height */
}

/* PAGE OVERRIDES */

#dienstleistungen_uebersicht h3 {
  background: #bfcbd6;
  padding: 0 5px;
  margin-bottom: 0;
  line-height: 2em;
}

#dienstleistungen_uebersicht ul {
  background: #bfcbd6;
  padding: 0 5px;
  margin-bottom: 1em;
}

#dienstleistungen_uebersicht li {
  border-bottom: 1px solid white;
  line-height: 2em;
  color: #002f5d;
}

#dienstleistungen_uebersicht li:last-child {
  border: none;
}

#kundenliste li {
  color: #536980;
  /*  margin-bottom: 1em;*/
}

.portrait {
  overflow: hidden;
  margin-bottom: 4em;
  display: block;
}

.portrait .photo {
  padding: 0 0 1em 0;
}

.portrait .photo img {
  display: block;
  width: 100%;
}

.credits {
  color: #555;
  font-size: 0.875em;
  margin-top: 4rem;
}

/* .portrait:nth-of-type(even) .photo {
  display: flex;
  justify-content: flex-end;
} */

/* .portrait:nth-of-type(even) .description {
  text-align: right;
} */

@media screen and (min-width: 460px) {
  .portrait {
    overflow: hidden;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
  }
  .portrait .photo {
    padding: 4px 24px 4px 0;
    /*  margin: 0 20px 0 0;*/
  }

  .portrait:nth-of-type(even) .photo {
    padding-left: 24px;
    padding-right: 0;
    padding: 4px 0 4px 24px;
    order: 2;
  }

  .portrait:nth-of-type(even) .description {
    text-align: right;
  }

  .portrait .photo img {
    width: 280px;
    max-width: 100%;
  }
}

.portrait .description {
  flex: 1 0 0%;
}

#content .portrait .description :last-child {
  margin-bottom: 0;
}

#newsletter_archive h3 {
  cursor: pointer;
  padding-left: 16px;
  line-height: 1.5;
  background-image: url(../images/newsletter_triangles.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  margin: 0;
}

#newsletter_archive h4 {
  cursor: pointer;
  line-height: 1.5;
  padding-left: 16px;
  margin: 0;
}

#newsletter_archive .newsletter {
  margin-bottom: 24px;
}
#newsletter_archive .newsletter.active {
  margin-bottom: 48px;
}

#newsletter_archive .newsletter.active h3 {
  background-position: 0 -72px;
}

#newsletter_archive .newsletter_content {
  margin-top: 24px;
  padding-left: 16px;
  display: none;
}

.newsletter ul {
  list-style: disc;
  margin-bottom: 24px;
  padding-left: 24px;
}

.newsletter ol {
  list-style: decimal;
  margin-bottom: 24px;
  padding-left: 24px;
}

/* ???? */

.dl {
  color: #002f5d;
}
strong {
  font-weight: 500;
}
em {
  font-style: italic;
}

.fld {
  border: #bfcbd6 1px solid;
  padding: 1px;
}

iframe {
  border: 1px solid #bfcbd6;
}

figure {
  margin: 0 0 24px 0;
  padding: 0;
}

figure > img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

figure.video > iframe {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

#content ul li {
  list-style: none;
  margin-left: 1.2em;
}

#content ul > li::before {
  position: absolute;
  content: "–";
  margin-left: -1.2em;
}
